import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-3db51886"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex align-items-stretch flex-shrink-0" }
const _hoisted_2 = {
  class: "d-flex ms-1 ms-lg-3",
  id: "kt_header_user_menu_toggle"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_KTUserMenu = _resolveComponent("KTUserMenu")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: _normalizeClass(["cursor-pointer symbol symbol-30px symbol-md-40px avatar-menu-container", { 'show menu-dropdown': _ctx.showMenu }]),
        "data-kt-menu-trigger": "click",
        "data-kt-menu-attach": "parent",
        "data-kt-menu-placement": "bottom-end",
        "data-kt-menu-flip": "bottom",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showMenu = !_ctx.showMenu)),
        ref: "userMenu"
      }, [
        _createVNode(_component_Avatar, {
          imageFile: _ctx.profilePicture,
          useIcon: false,
          acronym: _ctx.acronym,
          size: "40"
        }, null, 8, ["imageFile", "acronym"]),
        _createVNode(_component_KTUserMenu, {
          currentUser: _ctx.currentUser,
          image: _ctx.profilePicture,
          show: _ctx.showMenu
        }, null, 8, ["currentUser", "image", "show"])
      ], 2)
    ])
  ]))
}